import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import Loading from '../components/Loading';
import Grid from '../components/Grid';
import { useDevices } from '@domatic/query';

import { Box } from '@material-ui/core';
import moment from 'moment';

const Devices = ({ hub }) => {

    const { data: rows } = useDevices(hub.id);

    // const booleanFormatter = params => params.value ? '✓' : '';
    const dateFormatter = useCallback(params => moment(params.value).calendar(), []);

    const columns = useMemo(() => ([
        { field: 'name', headerName: 'Name', flex: 1 },
        { field: 'description', headerName: 'Description', flex: 1 },
        { field: 'activeStatus', headerName: 'Status', flex: 1 },
        { field: 'ip', headerName: 'IP', flex: 1 },
        { field: 'mac', headerName: 'MAC', flex: 1 },
        { field: 'type', headerName: 'Type', flex: 1 },
        { field: 'created', type: 'date', headerName: 'Created', flex: 1, valueFormatter: dateFormatter },
        { field: 'updated', type: 'date', headerName: 'Modified', flex: 1, valueFormatter: dateFormatter }
    ]), [dateFormatter]);

    const initialSortModel = [
        { field: 'type', sort: 'asc' }
    ];

    const active = false;
    const text = 'Loading...';

    return (
        <Box p={2} height="100%">
            <Loading active={active} spinner text={text}>
                <Grid
                    title={'Devices'}
                    rows={rows}
                    columns={columns}
                    initialSortModel={initialSortModel}
                >
                </Grid>
            </Loading>
        </Box>
    );
};

Devices.propTypes = {
    building: PropTypes.object,
    hub: PropTypes.object
};

Devices.defaultProps = {};

export default Devices;
